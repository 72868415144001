import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import config from '../../config';

import {
  InlineTextButton,
  MenuLabel,
  MenuContent,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
} from '../../components';
import { Grid, MenuItem, Menu, Tooltip, withStyles } from '@material-ui/core'
import DefaultImage from '../../assets/default-image.jpg'
import IconEye from '../../assets/icons/003_Compte/eye_password.svg'
import IconPencil from '../../assets/icons/annonces/Pencil.svg'
import IconInfos from '../../assets/icons/annonces/Information.svg'
import IconEllipsis from '../../assets/icons/007_Inbox/ellipsis.svg'


import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './ManageListingCard.module.css';

// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f3f3f3',
    color: '#4a4a4a',
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: 14
  },
}))(Tooltip);

const colorState = (state) => {
  switch (state) {
    case "published":
      return "#63cb4e"
    case "closed":
      return "#4a4a4a"
    case "pendingApproval":
      return "#fca520"
    case "draft":
      return "#fca520"
    case "pendingApproval":
      return "#fca520"
    default:
      return "#fca520"
  }
}

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};


export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    history,
    intl,
    viewport,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onOpenListing,
    onDiscardListing,
    onPublishListing,
    onToggleMenu,
    renderSizes,
    availabilityEnabled,
    onOpenModal,
    openModal,
    isMobile,
    currentUser
  } = props;

  console.log("ManageListingCard", props);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (status) => {
    switch (status) {
      case "draft":
        onDiscardListing(listing.id)
        break;
      case "published":
        onCloseListing(listing.id)
        break;
      case "publish_draft":
        onPublishListing(listing.id)
        break;
      case "closed":
        onOpenListing(listing.id)
        break;
      default:
        break;
    }
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    onOpenModal(true, currentListing.id)
    setAnchorEl(null);
  }

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state, publicData } = currentListing && currentListing.attributes;
  const category = publicData.category
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isPublished = state === "published";
  const hasStripeId = currentUser && currentUser.stripeAccount && currentUser.stripeAccount.attributes && currentUser.stripeAccount.attributes.stripeAccountId
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const hasError = hasOpeningError || hasClosingError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  return (
    <Grid
      container
      justify="center"
      justifyContent='center'
      className={css.container} spacing={3}>
      <Grid item xs={12} sm={4}>
        {
          !isDraft ?
            <NamedLink
              className={css.manageLink}
              name={isPendingApproval ? 'ListingPageVariant' : 'ListingPage'}
              params={{
                id,
                slug,
                variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT
              }}
            >
              <div style={{ height: "100%", display: 'flex', justifyContent: 'center', opacity: isDraft || isClosed || isPendingApproval ? 0.4 : 1 }}>
                <img src={firstImage ? firstImage.attributes.variants['landscape-crop'].url : DefaultImage} alt="first-image" className={css.image} />
              </div>
            </NamedLink>
            :
            <div style={{ height: "100%", display: 'flex', justifyContent: 'center' }}>
              <img src={firstImage ? firstImage.attributes.variants['landscape-crop'].url : DefaultImage} alt="first-image" className={css.image} />
            </div>
        }

      </Grid>

      <Grid item xs={12} sm={4}>
        <h2 className={css.title}>{formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}</h2>
        <p className={css.subtitle}><FormattedMessage id={`FieldCheckbox.${[category]}`} /></p>
        {
          formattedPrice &&
          <div>
            <p className={css.pricing}>{formattedPrice}
              {
                category !== "classifields" ?
                <span style={{ color: '#4a4a4a', fontWeight: 400 }}>
  / 
  <FormattedMessage 
    id={`ManageListingCard.${category === "garden_share" ? "perHour" : category === "tinyhouse" ? "perNight" : "perDay"}`} 
  />
</span>
                  : null
              }

            </p>
            
          </div>
        }
        {
          category === "garden_share" &&
          <p className={css.pricing}><FormattedMessage id={`ManageListingCard.priceNoRequired`} /></p>
        }
        <div className={css.statusWrapper}>
          <div className={css.status}>
            <p style={{
              backgroundColor: colorState(state),
              height: 10,
              width: 10,
              borderRadius: "50%",
              marginRight: 5
            }} />
            <p className={css.statusText}>
              {
                !thisListingInProgress ?
                  <FormattedMessage id={`ManageListingsPage.${[state]}`} />
                  : <span><FormattedMessage id="ManageListingCard.loading" />.</span>
              }
            </p>
          </div>
          {
            state !== "published" &&
            <Tooltip title={<FormattedMessage id={`ManageListingCard.toolTip.${state}`} />} aria-label="add">
              <img src={IconInfos} height="20" alt="informations" />
            </Tooltip>
          }

        </div>

      </Grid>
      <Grid item xs={12} sm={4}>
        <div className={css.actionWrapper}>
          {
            !isDraft ?
              <NamedLink
                className={css.manageLink}
                name={isPendingApproval ? 'ListingPageVariant' : 'ListingPage'}
                params={{
                  id,
                  slug,
                  variant: LISTING_PAGE_PENDING_APPROVAL_VARIANT
                }}
              >
                <p style={{ marginRight: 20, fontSize: 16 }}><img src={IconEye} alt="eye-icon" height="10" />&nbsp;&nbsp;
                  <FormattedMessage id="ManageListingCard.viewListing" />
                </p>
              </NamedLink>
              : null
          }
          <NamedLink
            className={css.manageLink}
            name="EditListingPage"
            params={{ id, slug, type: editListingLinkType, tab: 'description' }}
          >
            <p style={{ marginRight: 20, fontSize: 16 }}><img src={IconPencil} alt="pencil-icon" height="10" />&nbsp;&nbsp;<FormattedMessage id="ManageListingCard.editListing" /></p>
          </NamedLink>
          <p></p>
          {
            !isPendingApproval && !isDraft && <p className={css.plusButton} onClick={handleClick}><img src={IconEllipsis} alt="ellipsis-icon" height="3" />&nbsp;&nbsp;<FormattedMessage id="ManageListingCard.plus" /></p>
          }



          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* LM, 9/5/23. Ce cas ne peut pas arriver vu la condition d'apparition du menu
            -----------------------------
              isDraft ?
              <MenuItem onClick={() => handleClose("draft")}><FormattedMessage id="ManageListingCard.buttonDiscard" /></MenuItem>
            : null
          */
            }
            {/* LM, 9/5/23. Ce cas ne peut pas arriver vu la condition d'apparition du menu
            -----------------------------
            isDraft && hasStripeId ?
              <MenuItem onClick={() => handleClose("publish_draft")}><FormattedMessage id="ManageListingCard.buttonOpen" /></MenuItem>
            : null
          */
            }
            {
              isPublished &&
              <MenuItem onClick={() => handleClose("published")}><FormattedMessage id="ManageListingCard.buttonClose" /></MenuItem>
            }
            {
              isClosed &&
              <MenuItem onClick={() => handleClose("closed")}><FormattedMessage id="ManageListingCard.buttonOpen" /></MenuItem>
            }
          </Menu>
        </div>
      </Grid>
    </Grid>
  )
}

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(ManageListingCardComponent);
