import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { transaction, unitType, intl, category } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const translationKey = isNightly
    ? 'BookingBreakdown.baseUnitNight'
    : isDaily
      ? 'BookingBreakdown.baseUnitDay'
      : `BookingBreakdown.baseUnitQuantity${category}`;

  // Find correct line-item for given unitType prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/units', or 'line-item/time'
  // These are defined in '../../util/types';
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  //const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const quantity = (typeof transaction.attributes.protectedData !== "undefined") ? transaction.attributes.protectedData.nb_unit : unitPurchase.quantity;

  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
/*
  const nbPerson = (typeof transaction.attributes.protectedData !== "undefined") ? transaction.attributes.protectedData.nb_adult + transaction.attributes.protectedData.nb_child :
    unitPurchase.nbAdult + unitPurchase.nbChild;
*/
  //let price = (typeof transaction.attributes.protectedData !== "undefined") ? ((unitPurchase.unitPrice.amount / quantity) / 100) : ((unitPurchase.unitPrice.amount / nbPerson) / 100); // ((unitPurchase.unitPrice.amount / quantity ) * nbPerson) / 100;
  let price = unitPurchase.unitPrice.amount / 100;  //((unitPurchase.unitPrice.amount / quantity) / 100);

  if (category === "tinyhouse") {
    price = unitPurchase.unitPrice.amount / 100;
  }

  ////(unitPurchase.originPrice.amount * quantity)/100 
  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{price ? price + " €" : unitPrice} </span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
