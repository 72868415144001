import React, { Component } from 'react';
import { string, bool, arrayOf, array, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import classNames from 'classnames';
import moment from 'moment';
import config from '../../config';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { required, bookingDatesRequired, composeValidators } from '../../util/validators';
import { START_DATE, END_DATE } from '../../util/dates';
import { propTypes } from '../../util/types';
import { Form, IconSpinner, PrimaryButton, FieldDateInput } from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import RenderExtraFee from './RenderExtraFee'
import BookingEventType from './BookingEventType'
import BookingTimeArriving from './BookingTimeArriving'
import { Divider } from '@material-ui/core'
import MyInputField from './MyInputField';
import MyHiddenField from './MyHiddenField';
import { toStringDate } from '../../util/dates'

import css from './BookingDatesForm.module.css';

const identity = v => v;
// const ARRIVING_TIME =

export class BookingDatesFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { focusedInput: null };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  handleDate = (date, quantity = 1) => {
    const startDate = date;
    const d = new Date(startDate)
    const day = d.getDate() + quantity;
    const newDate = d.setDate(day);
    const endDate = new Date(newDate);

    // Set date for disable/enable button contactUser
    if (startDate) this.props.onSetHasDate(true)

    return {
      startDate,
      endDate
    }
  }

  // In case start or end date for the booking is missing
  // focus on that input, otherwise continue with the
  // default handleSubmit function.
  handleFormSubmit(e) {
    const { date } = e.bookingDates || {};
    const { startDate, endDate } = this.handleDate(date);
    const MIN_QTY = 1
    if (!e.bookingDates) return

    //e.quantity = e.nb_unit || 1
      

    //let { startDate, endDate } = this.handleDate(date, e.quantity)
    
    e.bookingDates.startDate = startDate
    e.bookingDates.endDate = endDate

    if (!startDate) {
      e.preventDefault();
      this.setState({ focusedInput: START_DATE });
    } else if (!endDate) {
      e.preventDefault();
      this.setState({ focusedInput: END_DATE });
    } else {
      e.hasBbqFee = e.bbq_fee && e.bbq_fee.length > 0;
      e.hasKitchenFee = e.kitchen_fee && e.kitchen_fee.length > 0;
      e.hasBarnumFee = e.barnum_fee && e.barnum_fee.length > 0;
      e.hasSoundFee = e.sound_fee && e.sound_fee.length > 0;
      e.hasSpaFee = e.spa_fee && e.spa_fee.length > 0;
      e.protectedData = {
        event_type: e.event_type,
        time_arriving: e.time_arriving,
        nb_adult: e.nb_adult || 1,
        nb_child: e.nb_child || 0,
        nb_unit: e.nb_unit || 1,
        hasBbqFee: e.bbq_fee && e.bbq_fee.length > 0,
        hasKitchenFee: e.kitchen_fee && e.kitchen_fee.length > 0,
        hasBarnumFee: e.barnum_fee && e.barnum_fee.length > 0,
        hasSoundFee: e.sound_fee && e.sound_fee.length > 0,
        hasSpaFee: e.spa_fee && e.spa_fee.length > 0
      }

      e.nbUnit = e.nb_unit || 1
     
      e.quantity = e.nb_unit || 1
      

      this.props.onSubmit(e);
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    if (this.props.isOwnListing) return null
    const MIN_QTY = 1
    const shouldFetchLineItem = this.props.category !== 'garden_share'
    const { date } =
      formValues.values && formValues.values.bookingDates ? formValues.values.bookingDates : {};

    if (this.props.handleProtectedData) {
      this.props.handleProtectedData(formValues.values)
    }

    // Add number of adult
    let nbAdult = formValues.values.nb_adult || 1
    let timeArriving = formValues.values.time_arriving || 1
    let nbChild = parseInt(formValues.values.nb_child) || 0
    nbChild = nbChild < 0 ? 0 : nbChild;
    let quantity = formValues.values.nb_unit || 1
    let nbUnit = 1
    let maxPeople = parseInt(formValues.values.capacity_max_people); 
    if (this.props.category === "garden_rent") {
      nbUnit = nbAdult + nbChild
    }
        console.log("FORMS/BOOKINGDATESFORM/BOOKINGDATESFORM.JS quantity", quantity);

    if (this.props.category === "garden_share" && parseInt(timeArriving) + parseInt(quantity) > parseInt(formValues.values.availability_to)) {
      formValues.values.nb_unit = parseInt(formValues.values.availability_to) - parseInt(timeArriving);
    }
    
    if (nbAdult + nbChild > maxPeople) {
      nbChild = maxPeople - nbAdult;
      formValues.values.nb_child = nbChild;
    }

    // -- Extra fees
    const hasBbqFee = formValues.values.bbq_fee && formValues.values.bbq_fee.length > 0;
    const hasKitchenFee = formValues.values.kitchen_fee && formValues.values.kitchen_fee.length > 0;
    const hasBarnumFee = formValues.values.barnum_fee && formValues.values.barnum_fee.length > 0;
    const hasSoundFee = formValues.values.sound_fee && formValues.values.sound_fee.length > 0;
    const hasSpaFee = formValues.values.spa_fee && formValues.values.spa_fee.length > 0;


    // Set date
    const { startDate, endDate } = this.handleDate(date, quantity)
    console.log("FORMS/BOOKINGDATESFORM/BOOKINGDATESFORM.JS");
    console.log(startDate, endDate, quantity);

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    if (!shouldFetchLineItem) return

    if (quantity && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, quantity, nbUnit, nbAdult, nbChild, hasBbqFee, hasKitchenFee, hasBarnumFee, hasSoundFee, hasSpaFee },
        listingId,
        isOwnListing,
      });
    }

    if (startDate && endDate && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData: { startDate, endDate, quantity, nbUnit, nbAdult, nbChild, hasBbqFee, hasKitchenFee, hasBarnumFee, hasSoundFee, hasSpaFee },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, category, listing, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const showBreakdown = category !== "garden_share"
    const showRequestBookingButton = category !== "garden_share"
    const maxPeople = listing.attributes.publicData ? listing.attributes.publicData.capacity_max_people : 10000

    let maxAdult = maxPeople;
    let maxChildren = maxPeople;



    if (!unitPrice && category !== "garden_share") {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (category !== "garden_share" && unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingDatesForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    const initialValues = this.props.initialValues
    //console.log("initialValues",initialValues);
    maxChildren = maxChildren - (initialValues && initialValues.nb_adult);

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        initialValues={{
          nb_child: initialValues && initialValues.nb_child,
          nb_adult: initialValues && initialValues.nb_adult,
          nb_unit: initialValues && initialValues.nb_unit,
          availability_to: listing.attributes.publicData && listing.attributes.publicData.availability_to,
          capacity_max_people: listing.attributes.publicData && listing.attributes.publicData.capacity_max_people,

          // bookingDates: {
          //   date: initialValues && initialValues.date ? toStringDate(initialValues.date) : null
          // }
        }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            formId,
            handleSubmit,
            intl,
            isOwnListing,
            submitButtonWrapperClassName,
            unitType,
            values,
            form,
            timeSlots,
            fetchTimeSlotsError,
            lineItems,
            extraFee,
            events,
            availabilityFrom,
            availabilityTo,
            capacityMaxPeople,
            fetchLineItemsInProgress,
            fetchLineItemsError
          } = fieldRenderProps;
          const { date } = values && values.bookingDates ? values.bookingDates : {};
          // Locale state  input
          const { nb_adult, nb_child, nb_unit } = values

          this.props.onSetHasEvent(!!values.event_type)

          const endDate = date;
          const startDate = date;

          const requiredMessage = intl.formatMessage({
            id: 'BookingDatesForm.requiredDate',
          });
          const startDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidStartDate',
          });
          const endDateErrorMessage = intl.formatMessage({
            id: 'FieldDateRangeInput.invalidEndDate',
          });
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;
          // This is the place to collect breakdown estimation data.
          // Note: lineItems are calculated and fetched from FTW backend
          // so we need to pass only booking data that is needed otherwise
          // If you have added new fields to the form that will affect to pricing,
          // you need to add the values to handleOnChange function
          const bookingData =
            startDate && endDate
              ? {
                unitType,
                startDate,
                endDate
              }
              : null;

          // const showEstimatedBreakdown =
          //   bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3>
                <FormattedMessage id="BookingDatesForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} category={category} values={values} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = showEstimatedBreakdown && fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const extraFeeMaybe = extraFee ? (
            extraFee.map((item, i) => {
              return <RenderExtraFee
                key={i}
                amount={item.amount}
                currency={item.currency}
                label={item.label}
                intl={intl}
              />
            })
          ) : null;


          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              {timeSlotsError}
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              <h3 style={{ fontWeight: 500 }}>{intl.formatMessage({ id: 'BookingDatesForm.placeholder' })}</h3>
              <FieldDateInput
                name="bookingDates"
                id="bookingDates"
                className={css.bookingDates}
                placeholderText={date || intl.formatMessage({ id: 'BookingDatesForm.date' })}
                timeSlots={timeSlots}
                // unitType={unitType}
                format={identity}
                disabled={isOwnListing}
                isOwnListing={isOwnListing}

              />
              <Field name="availability_to">
                {props => (
                  <div>
                    <MyHiddenField
                      name="availability_to"
                      id="availability_to"
                      currentValue={availabilityTo}
                      form={form}
                      {...props.input}
                    />
                  </div>
                )}
              </Field>

              <Field name="capacity_max_people">
                {props => (
                  <div>
                    <MyHiddenField
                      name="capacity_max_people"
                      id="capacity_max_people"
                      currentValue={capacityMaxPeople}
                      form={form}
                      {...props.input}
                    />
                  </div>
                )}
              </Field>
              {
                bookingData ?
                  <>
                    <div className={css.eventWrapper}>
                      <BookingEventType
                        category={category}
                        events={events}
                      />
                    </div>

                    {category !== "tinyhouse" &&
                      <div>
                        <BookingTimeArriving
                          availabilityFrom={availabilityFrom}
                          availabilityTo={availabilityTo} />
                      </div>
                    }
                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Field name="nb_unit">
                      {props => (
                        <div>
                          <MyInputField
                            name="nb_unit"
                            disabled={true}
                            currentValue={nb_unit || 1}
                            capacityMax={14}
                            capacityMin={1}
                            form={form}
                            title={intl.formatMessage({ id: `BookingDatesForm.${[category]}UnitTitle` })}
                            caption=""
                            placeholder={intl.formatMessage({ id: `BookingDatesForm.${[category]}Placeholder` })}
                            {...props.input}
                          />
                        </div>
                      )}
                    </Field>
                    {
                      category !== "classifields" && category !== "garden_share" &&
                      <Field name="nb_adult">
                        {props => (
                          <div>
                            <MyInputField
                              name="nb_adult"
                              disabled={true}
                              currentValue={nb_adult || 1}
                              capacityMax={maxAdult - 1}
                              capacityMin={1}
                              form={form}
                              title={intl.formatMessage({ id: 'BookingDatesForm.adults' })}
                              caption={intl.formatMessage({ id: 'BookingDatesForm.adultsSubtitle' })}
                              placeholder={intl.formatMessage({ id: 'BookingDatesForm.adultsPlaceholder' })}
                              {...props.input}
                            />
                          </div>
                        )}
                      </Field>
                    }
                    {
                      category !== "classifields" && category !== "garden_share" &&
                      <Field name="nb_child">
                        {props => (
                          <div>
                            <MyInputField
                              name="nb_child"
                              disabled={true}
                              currentValue={(nb_child < 0 ? 0 : nb_child) || '0'}
                              capacityMax={maxChildren - 1}
                              capacityMin={0}
                              form={form}
                              title={intl.formatMessage({ id: 'BookingDatesForm.child' })}
                              caption={intl.formatMessage({ id: 'BookingDatesForm.childSubtitle' })}
                              placeholder={intl.formatMessage({ id: 'BookingDatesForm.childPlaceholder' })}
                              {...props.input}
                            />
                          </div>
                        )}
                      </Field>
                    }

                    {
                      category !== "classifields" && category !== "garden_share" &&
                      <p className={css.wrapperBoxFormTip}>
                        <em>{intl.formatMessage({ id: 'BookingDatesForm.childTips' })}</em>
                      </p>
                    }
                  </>
                  : null
              }

              {startDate && lineItems && category !== "classifields" && category !== "garden_share" ?
                <div>
                  <h3><FormattedMessage
                    id="BookingDatesForm.extraTitle" /></h3>
                  {extraFeeMaybe}
                </div>
                : null}
              {showBreakdown && bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}

              {
                isOwnListing ?
                  <p className={css.smallPrint}>
                    <FormattedMessage
                      id={
                        isOwnListing
                          ? 'BookingDatesForm.ownListing'
                          : 'BookingDatesForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                  : <p> </p>
              }

              {
                showRequestBookingButton &&
                <div className={submitButtonClasses}>
                  <PrimaryButton
                    type="submit" style={{ margin: "0 auto", width: 180 }}
                    disabled={this.props.isOwnListing || !date || (!values.event_type && category !== "tinyhouse")}>
                    <FormattedMessage id="BookingDatesForm.requestToBook" />
                  </PrimaryButton>
                </div>
              }
            </Form>
          );
        }}
      />
    );
  }
}

BookingDatesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  timeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingDatesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  timeSlots: arrayOf(propTypes.timeSlot),

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingDatesForm = compose(injectIntl)(BookingDatesFormComponent);
BookingDatesForm.displayName = 'BookingDatesForm';

export default BookingDatesForm;
